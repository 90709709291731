import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useMessageBox from './useMessageBox';

function useUserDeleteRole() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { del } = useFetch();

    const promptDeleteRole = useCallback(
        (
            userId: string,
            screenName: string,
            roleId: string,
            roleName: string,
            onComplete?: (() => void) | undefined,
        ) => {
            msgBox({
                title: 'Eject User from Role',
                icon: 'question',
                buttons: ['yes', 'no'],
                children: (
                    <p>
                        Are you sure you want to eject {screenName} from the <strong>{roleName}</strong> role?
                    </p>
                ),
                onClose: async result => {
                    if (result === 'yes') {
                        setIsBusy(true);

                        try {
                            await del(`/users/${userId}/roles/${roleId}`);
                            onComplete?.();
                        } catch {
                            msgBox({
                                title: 'Error',
                                icon: 'error',
                                buttons: ['ok'],
                                children: (
                                    <p>There was a problem ejecting the user from this role. Please try again.</p>
                                ),
                            });
                        } finally {
                            setIsBusy(false);
                        }
                    }
                },
            });
        },
        [msgBox, del],
    );

    return { promptDeleteRole, isBusy };
}

export default useUserDeleteRole;
