/* eslint-disable import/prefer-default-export */
const formatBytes = (bytes: number) => {
    if (bytes < 1024) {
        return `${bytes}B`;
    }

    const kb = bytes / 1024;

    if (kb < 1024) {
        return `${kb.toFixed(1)}KB`;
    }

    const mb = kb / 1024;
    return `${mb.toFixed(1)}MB`;
};

export { formatBytes };
