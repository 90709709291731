import { useEffect } from 'react';
import useKeywordsList from '@hooks/useKeywordsList';
import styles from './KeywordsHome.module.css';
import KeywordsList from './components/KeywordsList';

function KeywordsHome() {
    const { status, items, load } = useKeywordsList();

    useEffect(() => {
        if (status === 'idle') load();
    }, [status, load]);

    if (status === 'idle') return null;
    if (status === 'loading' && items.length === 0) return <div className={styles.centerMessage}>Loading...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Request failed. Please try again.</div>;
    if (status === 'done' && items.length === 0) return <div className={styles.centerMessage}>No keywords found.</div>;

    return <KeywordsList />;
}

export default KeywordsHome;
