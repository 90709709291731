import useAppsList from '@hooks/useAppsList';
import { useEffect } from 'react';
import styles from './AppsHome.module.css';
import AppsList from './components/AppsList';

function Home() {
    const { status, items, load } = useAppsList();

    useEffect(() => {
        if (status === 'idle') load();
    }, [status, load]);

    if (status === 'idle') return null;
    if (status === 'loading' && items.length === 0) return <div className={styles.centerMessage}>Loading...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Request failed. Please try again.</div>;
    if (status === 'done' && items.length === 0) return <div className={styles.centerMessage}>No apps found.</div>;

    return <AppsList />;
}

export default Home;
