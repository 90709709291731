import { useCallback, useEffect, useState } from 'react';
import useAppPermissions from './useAppPermissions';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';

function useAppAddPermission() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { load: loadPermissions, status, items } = useAppPermissions();
    const { post } = useFetch();

    useEffect(() => {
        if (status === 'idle') {
            loadPermissions();
        }
    }, [status, loadPermissions]);

    const promptAddPermission = useCallback(
        (appId: string, onComplete: (() => void) | undefined) => {
            if (items.length === 0) return;
            loadPermissions();

            inputBox({
                title: 'Add Permission',
                prompt: 'Select the permission to add to the app:',
                dropDownOptions: items.map(r => ({ name: r.name, value: r.id })),
                onOK: async permission => {
                    try {
                        setIsBusy(true);
                        await post(`/apps/${appId}/permissions`, { permission });
                        onComplete?.();
                    } catch (err) {
                        msgBox({
                            title: 'Error',
                            icon: 'error',
                            buttons: ['ok'],
                            children: <p>There was a problem adding the permission to the app. Please try again.</p>,
                        });
                    } finally {
                        setIsBusy(false);
                    }
                },
            });
        },
        [items, loadPermissions, inputBox, msgBox, post],
    );

    return { canAddPermission: items.length > 0, promptAddPermission, isBusy };
}

export default useAppAddPermission;
