/* eslint-disable jsx-a11y/label-has-associated-control */
import LoadingOverlay from '@components/LoadingOverlay';
import useScopeAddResource from '@hooks/useScopeAddResource';
import useScopeDeleteResource from '@hooks/useScopeDeleteResource';
import useScopesList from '@hooks/useScopesList';
import { useCallback } from 'react';
import { ScopeDetail } from 'src/models';
import styles from './ScopeDetails.module.css';

interface Props {
    scope: ScopeDetail;
}

function ScopeDetails({ scope }: Props) {
    const { load } = useScopesList();
    const { promptAddResource, canAddResource, isBusy: isAddResourceBusy } = useScopeAddResource();
    const { promptDeleteResource, isBusy: isDeleteResourceBusy } = useScopeDeleteResource();
    const { id, displayName, name, description, resources } = scope;
    const handleAddResource = useCallback(() => promptAddResource(id, () => load()), [id, promptAddResource, load]);
    const handleDeleteResource = useCallback(
        (appId: string, appName: string) => promptDeleteResource(id, appId, appName, () => load()),
        [id, promptDeleteResource, load],
    );

    const isBusy = isAddResourceBusy || isDeleteResourceBusy;

    return (
        <fieldset className={styles.container}>
            <div className={styles.details}>
                <div className={styles.section}>
                    <div>
                        <strong>{displayName}</strong> <em>({name})</em>
                    </div>
                    <span>{description}</span>
                </div>
                <div className={styles.section}>
                    <strong>Resources</strong>
                    {resources.length === 0 && <em>No additional resources defined for this scope.</em>}
                    {resources.length > 0 && (
                        <div className={styles.list}>
                            {resources.map(app => (
                                <div key={app.id} className={styles.listItem}>
                                    <span>{app.name}</span>
                                    <button
                                        className="link"
                                        type="button"
                                        onClick={() => handleDeleteResource(app.id, app.name)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <fieldset className={styles.actions}>
                <legend>Actions</legend>
                <button type="button" disabled={!canAddResource} onClick={handleAddResource}>
                    Add Resource
                </button>
            </fieldset>
            {isBusy && <LoadingOverlay />}
        </fieldset>
    );
}

export default ScopeDetails;
