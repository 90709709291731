import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useMessageBox from './useMessageBox';

function useUserLock() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { post, del } = useFetch();

    const lockUser = useCallback(
        async (userId: string, onComplete: (() => void) | undefined) => {
            try {
                setIsBusy(true);
                await post(`/users/${userId}/lock`);
                onComplete?.();
            } catch (err) {
                msgBox({
                    title: 'Error',
                    icon: 'error',
                    buttons: ['ok'],
                    children: <p>There was a problem locking the user. Please try again.</p>,
                });
            } finally {
                setIsBusy(false);
            }
        },
        [msgBox, post],
    );

    const unlockUser = useCallback(
        async (userId: string, onComplete: (() => void) | undefined) => {
            try {
                setIsBusy(true);
                await del(`/users/${userId}/lock`);
                onComplete?.();
            } catch (err) {
                msgBox({
                    title: 'Error',
                    icon: 'error',
                    buttons: ['ok'],
                    children: <p>There was a problem locking the user. Please try again.</p>,
                });
            } finally {
                setIsBusy(false);
            }
        },
        [msgBox, del],
    );

    return { lockUser, unlockUser, isBusy };
}

export default useUserLock;
