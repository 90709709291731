import Modal from '@components/Modal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardBanner from '@assets/wizard-banner.png';
import useFetch from '@hooks/useFetch';
import useMessageBox from '@hooks/useMessageBox';
import styles from './AppCreateWizard.module.css';
import Step1 from './components/AppCreateWizard/Step1';
import { AppType, ClientType, ConsentType, CreateResult, Flow } from './components/AppCreateWizard/model';
import Step2 from './components/AppCreateWizard/Step2';

function AppCreateWizard() {
    const navigate = useNavigate();
    const { post } = useFetch();
    const { msgBox } = useMessageBox();
    const handleClose = useCallback(() => navigate(-1), [navigate]);
    const [stepNumber, setStepNumber] = useState(1);
    const [appType, setAppType] = useState<AppType>();
    const [displayName, setDisplayName] = useState('');
    const [redirectUri, setRedirectUri] = useState(appType === 'Backend' ? 'reaol://oauth-reply' : 'https://');
    const [clientType, setClientType] = useState<ClientType | undefined>(
        appType === 'Backend' ? 'confidential' : undefined,
    );
    const [flows, setFlows] = useState<Flow[]>([]);
    const [consentType, setConsentType] = useState<ConsentType>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isValid =
        (stepNumber === 1 && !!appType) ||
        (stepNumber === 2 &&
            !!displayName &&
            !!redirectUri &&
            !!clientType &&
            flows.length > 0 &&
            ((flows.includes('AuthorizationCode') && !!consentType) || !flows.includes('AuthorizationCode')));
    const handleNext = useCallback(() => {
        if (stepNumber === 1) {
            setStepNumber(2);
        } else {
            const doSubmit = async () => {
                setIsSubmitting(true);
                try {
                    const result = await post<CreateResult>('/apps', {
                        displayName,
                        redirectUri,
                        clientType,
                        consentType,
                        flows,
                    });
                    msgBox({
                        title: 'Success',
                        icon: 'info',
                        buttons: ['ok'],
                        children: (
                            <>
                                <p>App created! The Client ID is:</p>
                                <pre>{result?.clientId}</pre>
                                {result?.clientSecret && (
                                    <>
                                        <p>Your client secret is:</p>
                                        <pre>{result.clientSecret}</pre>
                                        <p>Please copy it now. It cannot be retrieved later.</p>
                                    </>
                                )}
                            </>
                        ),
                        onClose: () => navigate('/apps'),
                    });
                } catch {
                    msgBox({
                        title: 'Failed to Create App',
                        children: <p>Failed to create the app. Please try again later or reach out to a dev.</p>,
                        icon: 'error',
                        buttons: ['ok'],
                    });
                } finally {
                    setIsSubmitting(false);
                }
            };

            doSubmit();
        }
    }, [stepNumber, clientType, displayName, redirectUri, consentType, flows, msgBox, navigate, post]);
    const handleBack = useCallback(() => setStepNumber(v => v - 1), []);
    const handleSelectAppType = useCallback((value: AppType) => setAppType(value), []);

    useEffect(() => {
        if (appType === 'Backend') {
            setRedirectUri('reaol://oauth-reply');
        } else {
            setRedirectUri('https://');
        }
    }, [appType]);

    return (
        <Modal isOpen isSemiTransparent zIndex={5}>
            <div className="window" style={{ cursor: 'default', width: 640, height: 480 }}>
                <div className="title-bar">
                    <div className="title-bar-text">Create New App</div>
                    <div className="title-bar-controls">
                        <button onClick={handleClose} type="button" aria-label="Close" disabled={isSubmitting} />
                    </div>
                </div>
                <div className={`window-body ${styles.windowBody}`}>
                    <div className={styles.contents}>
                        <div className={styles.banner} style={{ backgroundImage: `url(${WizardBanner})` }} />
                        <div className={styles.wizardInner}>
                            {stepNumber === 1 && <Step1 appType={appType} onSelect={handleSelectAppType} />}
                            {!!appType && stepNumber === 2 && (
                                <Step2
                                    appType={appType}
                                    displayName={displayName}
                                    onDisplayNameChange={v => setDisplayName(v)}
                                    redirectUri={redirectUri}
                                    onRedirectUriChange={v => setRedirectUri(v)}
                                    clientType={clientType}
                                    onClientTypeChange={v => setClientType(v)}
                                    flows={flows}
                                    onFlowsChange={v => setFlows(v)}
                                    consentType={consentType}
                                    onConsentTypeChange={v => setConsentType(v)}
                                    disabled={isSubmitting}
                                />
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className={styles.buttons}>
                        <button type="button" disabled={stepNumber === 1 || isSubmitting} onClick={handleBack}>
                            &lt;&nbsp;&nbsp;Back
                        </button>
                        <button type="button" disabled={!isValid || isSubmitting} onClick={handleNext}>
                            {stepNumber === 1 && <>Next&nbsp;&nbsp;&gt;</>}
                            {stepNumber === 2 && <>Submit</>}
                        </button>
                        <button type="button" onClick={handleClose} disabled={isSubmitting}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AppCreateWizard;
