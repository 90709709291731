import { AppType } from './model';
import styles from './Step1.module.css';

interface Props {
    appType: AppType | undefined;
    onSelect: (value: AppType) => void;
}

function Step1({ appType, onSelect }: Props) {
    return (
        <div className={styles.container}>
            <div>
                <strong>To create a new app, please select the type of application:</strong>
            </div>
            <div className="field-row">
                <input
                    id="appTypeWebMobileApp"
                    type="radio"
                    name="appType"
                    checked={appType === 'WebOrMobile'}
                    onChange={() => onSelect('WebOrMobile')}
                />
                <label htmlFor="appTypeWebMobileApp">Web or Mobile Application</label>
            </div>
            <div className="field-row">
                <input
                    id="appTypeService"
                    type="radio"
                    name="appType"
                    checked={appType === 'Backend'}
                    onChange={() => onSelect('Backend')}
                />
                <label htmlFor="appTypeService">Backend Service</label>
            </div>
            <div>
                <p>
                    In a web or mobile application, users are authenticated via a series of redirects to the OAuth
                    server and back to the app. Apps can either be <strong>Public</strong> or{' '}
                    <strong>Confidential</strong>.
                </p>
                <p>
                    <strong>Public apps</strong> do not have a client secret and instead communicate with the OAuth
                    server using only a public client ID. The frontend application can exchange the authorization code
                    for a token directly.
                </p>
                <p>
                    <strong>Confidential apps</strong> have a client secret that must be kept private. A backend API is
                    required to exchange the authorization code for an access token.
                </p>
                <p>
                    In a Backend Service application, the service can either act as itself or perform authentication
                    using user-supplied credentials. Backend services must be Confidential and use either the{' '}
                    <strong>Client Credentials</strong> or <strong>Password</strong> flows.
                </p>
                <p>
                    The <strong>Client Credentials</strong> flow is used when the API or service needs to authenticate
                    as itself. It cannot perform actions on behalf of a user.
                </p>
                <p>
                    The <strong>Password</strong> flow is used when the API or service needs to validate a user. It does{' '}
                    <em>not</em> return a token and therefore cannot perform actions on behalf of a user.
                </p>
            </div>
        </div>
    );
}

export default Step1;
