import { useCallback, useEffect, useMemo, useState } from 'react';
import useAppsList from './useAppsList';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';

function useUserAddAuthorization() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { items, status, load } = useAppsList();
    const { post } = useFetch();
    const externalApps = useMemo(() => items.filter(app => app.consentType === 'external'), [items]);

    useEffect(() => {
        if (status !== 'idle') return;

        const doLoad = async () => {
            await load();
        };
        doLoad();
    }, [status, load]);

    const promptAddAuthorization = useCallback(
        (userId: string, onComplete: (() => void) | undefined) => {
            if (externalApps.length === 0) return;

            inputBox({
                title: 'Add External Authorization',
                prompt: 'Select the app to externally authorize for the user:',
                dropDownOptions: externalApps.map(app => ({ name: app.displayName, value: app.id })),
                onOK: async appId => {
                    try {
                        setIsBusy(true);
                        await post(`/users/${userId}/authorizations`, { appId });
                        onComplete?.();
                    } catch (err) {
                        msgBox({
                            title: 'Error',
                            icon: 'error',
                            buttons: ['ok'],
                            children: <p>There was a problem authorizing the user for that app. Please try again.</p>,
                        });
                    } finally {
                        setIsBusy(false);
                    }
                },
            });
        },
        [externalApps, inputBox, msgBox, post],
    );

    return { canAddAuthorization: externalApps.length > 0, promptAddAuthorization, isBusy };
}

export default useUserAddAuthorization;
