/* eslint-disable jsx-a11y/label-has-associated-control */
import LoadingOverlay from '@components/LoadingOverlay';
import useAppAddPermission from '@hooks/useAppAddPermission';
import useAppAddScope from '@hooks/useAppAddScope';
import useAppDelete from '@hooks/useAppDelete';
import useAppDeletePermission from '@hooks/useAppDeletePermission';
import useAppDeleteScope from '@hooks/useAppDeleteScope';
import useAppsList from '@hooks/useAppsList';
import { useCallback } from 'react';
import { AppDetail } from 'src/models';
import styles from './AppDetails.module.css';

interface Props {
    app: AppDetail;
}

function AppDetails({ app }: Props) {
    const { load } = useAppsList();
    const { promptAddScope, canAddScope, isBusy: isAddScopeBusy } = useAppAddScope();
    const { promptDeleteScope, isBusy: isDeleteScopeBusy } = useAppDeleteScope();
    const { promptAddPermission, canAddPermission, isBusy: isAddPermissionBusy } = useAppAddPermission();
    const { promptDeletePermission, isBusy: isDeletePermissionBusy } = useAppDeletePermission();
    const { promptDeleteApp, isBusy: isDeleteBusy } = useAppDelete();
    const { id, displayName, type, consentType, consentTypeDescription, author, clientId } = app;
    const handleAddScope = useCallback(() => promptAddScope(id, () => load()), [id, promptAddScope, load]);
    const handleDeleteScope = useCallback(
        (scopeId: string, scopeName: string) => promptDeleteScope(id, scopeId, scopeName, () => load()),
        [id, promptDeleteScope, load],
    );
    const handleAddPermission = useCallback(
        () => promptAddPermission(id, () => load()),
        [id, promptAddPermission, load],
    );
    const handleDeletePermission = useCallback(
        (permissionId: string, permissionName: string) =>
            promptDeletePermission(id, permissionId, permissionName, () => load()),
        [id, promptDeletePermission, load],
    );
    const handleDeleteApp = useCallback(
        () => promptDeleteApp(id, displayName, () => load()),
        [id, displayName, promptDeleteApp, load],
    );

    const isBusy = isAddScopeBusy || isDeleteScopeBusy || isAddPermissionBusy || isDeletePermissionBusy || isDeleteBusy;

    return (
        <fieldset className={styles.container}>
            <div className={styles.details}>
                <div className={styles.section}>
                    <div>
                        <strong>{displayName}</strong> <em>({type})</em>
                    </div>
                    {author && author.id && <span>Owned by {author?.name}</span>}
                    {(!author || !author.id) && <span>System App</span>}
                </div>
                <div className={styles.section}>
                    <strong>Client ID</strong>
                    <span>{clientId}</span>
                </div>
                <div className={styles.section}>
                    <strong>Consent Type</strong>
                    <span>{consentType}</span>
                    <em>{consentTypeDescription}</em>
                </div>
                <div className={styles.section}>
                    <strong>Redirect URI(s)</strong>
                    {app.redirectUris.length === 0 && <em>None.</em>}
                    {app.redirectUris.length > 0 && (
                        <div className={styles.list}>
                            {app.redirectUris.map(uri => (
                                <div key={uri} className={styles.listItem}>
                                    <span>{uri}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <strong>Permissions</strong>
                    {app.permissions.length === 0 && <em>None.</em>}
                    {app.permissions.length > 0 && (
                        <div className={styles.list}>
                            {app.permissions.map(permission => (
                                <div key={permission.id} className={styles.listItem}>
                                    <span>{permission.name}</span>
                                    <button
                                        className="link"
                                        type="button"
                                        onClick={() => handleDeletePermission(permission.id, permission.name)}
                                    >
                                        Revoke
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <strong>Scopes</strong>
                    {app.scopes.length === 0 && <em>None.</em>}
                    {app.scopes.length > 0 && (
                        <div className={styles.list}>
                            {app.scopes.map(scope => (
                                <div key={scope.id} className={styles.listItem}>
                                    <span>{scope.name}</span>
                                    <button
                                        className="link"
                                        type="button"
                                        onClick={() => handleDeleteScope(scope.id, scope.name)}
                                    >
                                        Revoke
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <fieldset className={styles.actions}>
                <legend>Actions</legend>
                <button type="button" disabled={!canAddPermission} onClick={handleAddPermission}>
                    Add Permission
                </button>
                <button type="button" disabled={!canAddScope} onClick={handleAddScope}>
                    Add Scope
                </button>
                <button type="button" onClick={handleDeleteApp}>
                    Delete App
                </button>
            </fieldset>
            {isBusy && <LoadingOverlay />}
        </fieldset>
    );
}

export default AppDetails;
