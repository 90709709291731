import AudibleLink from './AudibleLink';
import styles from './ToolbarButton.module.css';

interface Props {
    title?: string | undefined;
    icon?: string | undefined;
    to?: string | undefined;
    onClick?: () => void | undefined;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean | undefined;
}

function ToolbarButton({ title, icon, to, onClick, type, disabled }: Props) {
    if (to && !disabled) {
        return (
            <AudibleLink to={to} className={`${styles.btn}`} title={title} draggable={false}>
                {icon && <img src={icon} alt={title || ''} draggable={false} />}
                {title && <span>{title}</span>}
            </AudibleLink>
        );
    }

    return (
        <button
            draggable={false}
            className={`${styles.btn} ${disabled ? styles.disabled : ''}`}
            disabled={disabled}
            // eslint-disable-next-line react/button-has-type
            type={type || 'button'}
            onClick={onClick}
        >
            {icon && <img src={icon} alt={title || ''} draggable={false} />}
            {title && <span>{title}</span>}
        </button>
    );
}

ToolbarButton.defaultProps = {
    title: undefined,
    icon: undefined,
    to: undefined,
    onClick: undefined,
    type: 'button',
    disabled: false,
};

export default ToolbarButton;
