/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormEvent, useCallback, useState } from 'react';
import ToolbarButton from '@components/ToolbarButton';
import useUsersSearch from '@hooks/useUsersSearch';
import styles from './UsersSearchForm.module.css';

function UsersSearchForm() {
    const { search, status } = useUsersSearch();
    const [query, setQuery] = useState('');

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            search(query);
        },
        [search, query],
    );

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <strong>&raquo; Find a User</strong>
            <input
                id="query"
                name="query"
                type="text"
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder="Screen Name"
                disabled={status === 'loading'}
            />
            <ToolbarButton title="Search" type="submit" />
        </form>
    );
}

export default UsersSearchForm;
