import { useCallback, useEffect, useState } from 'react';
import useAppScopes from './useAppScopes';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';

function useAppAddScope() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { load: loadScopes, status, items } = useAppScopes();
    const { post } = useFetch();

    useEffect(() => {
        if (status === 'idle') {
            loadScopes();
        }
    }, [status, loadScopes]);

    const promptAddScope = useCallback(
        (appId: string, onComplete: (() => void) | undefined) => {
            if (items.length === 0) return;
            loadScopes();

            inputBox({
                title: 'Add Scope',
                prompt: 'Select the scope to add to the app:',
                dropDownOptions: items.map(r => ({ name: r.name, value: r.id })),
                onOK: async scopeId => {
                    try {
                        setIsBusy(true);
                        await post(`/apps/${appId}/scopes`, { scopeId });
                        onComplete?.();
                    } catch (err) {
                        msgBox({
                            title: 'Error',
                            icon: 'error',
                            buttons: ['ok'],
                            children: <p>There was a problem adding the scope to the app. Please try again.</p>,
                        });
                    } finally {
                        setIsBusy(false);
                    }
                },
            });
        },
        [items, loadScopes, inputBox, msgBox, post],
    );

    return { canAddScope: items.length > 0, promptAddScope, isBusy };
}

export default useAppAddScope;
