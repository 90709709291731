import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '98.css';
import '@assets/site.css';
import Layout from '@components/Layout';
import Welcome from '@pages/Welcome';
import UsersLayout from '@pages/Users/UsersLayout';
import UsersHome from '@pages/Users/UsersHome';
import OAuthReply from '@pages/OAuthReply';
import Login from '@pages/Login';
import AppsLayout from '@pages/Apps/AppsLayout';
import AppsHome from '@pages/Apps/AppsHome';
import AppDetailsPage from '@pages/Apps/AppDetailsPage';
import AppCreateWizard from '@pages/Apps/AppCreateWizard';
import Logoff from '@pages/Logoff';
import LoginError from '@pages/LoginError';
import ScopesLayout from '@pages/Scopes/ScopesLayout';
import ScopesHome from '@pages/Scopes/ScopesHome';
import ScopeCreateDialog from '@pages/Scopes/components/ScopeCreateDialog';
import ModQueueLayout from '@pages/ModQueue/ModQueueLayout';
import ModQueueHome from '@pages/ModQueue/ModQueueHome';
import KeywordsLayout from '@pages/Keywords/KeywordsLayout';
import KeywordsHome from '@pages/Keywords/KeywordsHome';
import KeywordsCreate from '@pages/Keywords/KeywordsCreate';
import DbViewLayout from '@pages/DbView/DbViewLayout';
import RequireAuth from './RequireAuth';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                >
                    <Route path="welcome" element={<Welcome />} />
                    <Route path="users" element={<UsersLayout />}>
                        <Route index element={<UsersHome />} />
                    </Route>
                    <Route path="apps" element={<AppsLayout />}>
                        <Route index element={<AppsHome />} />
                        <Route path="create" element={<AppCreateWizard />} />
                        <Route path=":appId" element={<AppDetailsPage />} />
                    </Route>
                    <Route path="scopes" element={<ScopesLayout />}>
                        <Route index element={<ScopesHome />} />
                        <Route path="create" element={<ScopeCreateDialog />} />
                    </Route>
                    <Route path="keywords" element={<KeywordsLayout />}>
                        <Route index element={<KeywordsHome />} />
                        <Route path="create" element={<KeywordsCreate />} />
                    </Route>
                    <Route path="mod-queue" element={<ModQueueLayout />}>
                        <Route index element={<ModQueueHome />} />
                    </Route>
                    <Route path="dbview" element={<DbViewLayout />} />
                    <Route path="logoff" element={<Logoff />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/oauth-reply" element={<OAuthReply />} />
                <Route path="/login-error" element={<LoginError />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
