import { useCallback, useEffect, useState } from 'react';
import useAppsList from './useAppsList';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';

function useScopeAddResource() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { load: loadApps, status, items } = useAppsList();
    const { post } = useFetch();

    useEffect(() => {
        if (status === 'idle') {
            loadApps();
        }
    }, [status, loadApps]);

    const promptAddResource = useCallback(
        (scopeId: string, onComplete: (() => void) | undefined) => {
            if (items.length === 0) return;
            loadApps();

            inputBox({
                title: 'Add Resource',
                prompt: 'Select the resource to add to the scope:',
                dropDownOptions: items.map(r => ({ name: r.displayName, value: r.id })),
                onOK: async appId => {
                    try {
                        setIsBusy(true);
                        await post(`/scopes/${scopeId}/resources`, { appId });
                        onComplete?.();
                    } catch (err) {
                        msgBox({
                            title: 'Error',
                            icon: 'error',
                            buttons: ['ok'],
                            children: <p>There was a problem adding the resource to the scope. Please try again.</p>,
                        });
                    } finally {
                        setIsBusy(false);
                    }
                },
            });
        },
        [items, loadApps, inputBox, msgBox, post],
    );

    return { canAddResource: items.length > 0, promptAddResource, isBusy };
}

export default useScopeAddResource;
