import { useEffect } from 'react';
import { useGlobal } from 'reactn';
import INITIAL_STATE from '../initial-state';

function Logoff() {
    const [, setGlobal] = useGlobal();
    useEffect(() => {
        setGlobal(INITIAL_STATE);
    }, [setGlobal]);
    return null;
}

export default Logoff;
