import { State } from 'reactn/default';

const INITIAL_STATE: State = {
    codeVerifier: '',
    accessToken: '',
    expiresAt: new Date(0),
    users: {
        search: {
            query: '',
            status: 'idle',
            results: [],
            hasMore: false,
        },
        ugc: {},
    },
    roles: {
        status: 'idle',
        items: [],
    },
    apps: {
        status: 'idle',
        items: [],
    },
    app_scopes: {
        status: 'idle',
        items: [],
    },
    app_permissions: {
        status: 'idle',
        items: [],
    },
    scopes: {
        status: 'idle',
        items: [],
    },
    modQ: {
        status: 'idle',
        items: [],
    },
    keywords: {
        status: 'idle',
        items: [],
    },
};

export default INITIAL_STATE;
