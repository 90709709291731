import { useCallback, useRef, useState } from 'react';
import { ObjectContent } from 'src/models';
import useCMSFetch from './useCMSFetch';

interface EnumerateResponse {
    hasMore: boolean;
    token: number;
    items: ObjectContent[];
}

export default function useDbViewCursor(type: string) {
    const hasInit = useRef(false);
    const isRequestActive = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(0);
    const [hasMore, setHasMore] = useState<boolean>();
    const [items, setItems] = useState<ObjectContent[]>([]);
    const { get } = useCMSFetch();

    const loadNext = useCallback(async () => {
        if (isRequestActive.current) return;

        isRequestActive.current = true;
        setIsLoading(true);
        const nextChunk = await get<EnumerateResponse>(`/content/${type}?token=${token}`);

        if (nextChunk) {
            setHasMore(nextChunk?.hasMore);
            setToken(nextChunk?.token);
            setItems(state => [...state, ...nextChunk.items]);
        }

        isRequestActive.current = false;
        setIsLoading(false);
    }, [get, type, token]);

    if (!hasInit.current) {
        hasInit.current = true;
        loadNext();
    }

    return { loadNext, hasMore: hasMore || items.length === 0, items, isLoading };
}
