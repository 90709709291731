import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { AppDetail, RequestStatus } from 'src/models';
import useFetch from './useFetch';

export default function useAppsList() {
    const [apps] = useGlobal('apps');
    const { get } = useFetch();

    const setItems = useDispatch((global, _, items: AppDetail[]) => ({
        apps: {
            ...global.apps,
            status: 'done' as RequestStatus,
            items,
        },
    }));

    const setStatus = useDispatch((global, _, status: RequestStatus) => ({
        apps: {
            ...global.apps,
            status,
        },
    }));

    const load = useCallback(async () => {
        try {
            setStatus('loading');
            const response = (await get(`/apps`)) as AppDetail[];

            setItems(response);
        } catch (err) {
            setStatus('failed');
        }
    }, [get, setStatus, setItems]);

    const { status, items } = apps;

    return { load, status, items };
}
