import { Outlet } from 'react-router-dom';
import Icon from '@assets/icon.png';
import AppsIcon from '@assets/toolbar/apps.png';
import LogoffIcon from '@assets/toolbar/logoff.png';
import RolesIcon from '@assets/toolbar/roles.png';
import ScopesIcon from '@assets/toolbar/scopes.png';
import UsersIcon from '@assets/toolbar/users.png';
import KeywordsIcon from '@assets/toolbar/keywords.png';
import ModQueueIcon from '@assets/toolbar/moderation-queue.png';
import ObjectsIcon from '@assets/toolbar/objects.png';
import WarningIcon from '@assets/warning16.png';
import { useEffect, useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { formatDistanceToNowStrict, intervalToDuration, isPast } from 'date-fns';
import { SWRConfig } from 'swr';
import { MessageBoxProvider } from '@hooks/useMessageBox';
import { InputBoxProvider } from '@hooks/useInputBox';
import styles from './Layout.module.css';
import Toolbar from './Toolbar';
import ToolbarButton from './ToolbarButton';
import ToolbarDivider from './ToolbarDivider';

function Layout() {
    const [accessToken] = useGlobal('accessToken');
    const [expiresAt] = useGlobal('expiresAt');
    const [expiry, setExpiry] = useState('');
    const [expiryWarnState, setExpiryWarnState] = useState<'none' | 'warning' | 'expired'>('none');
    const fetcher = useCallback(
        async (url: string) => {
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return res.json();
        },
        [accessToken],
    );

    useEffect(() => {
        const timer = setInterval(() => {
            setExpiry(formatDistanceToNowStrict(expiresAt));

            if (isPast(expiresAt)) {
                window.location.reload();
            } else {
                const timeLeft = intervalToDuration({ start: new Date(), end: expiresAt });
                setExpiryWarnState(!timeLeft.minutes || timeLeft.minutes < 10 ? 'warning' : 'none');
            }
        }, 500);

        return () => clearInterval(timer);
    }, [expiresAt]);

    return (
        <SWRConfig value={{ fetcher }}>
            <MessageBoxProvider>
                <InputBoxProvider>
                    <div className={styles.container}>
                        <div className={`window ${styles.window}`}>
                            <div className={`title-bar ${styles.titleBar}`}>
                                <img className="title-bar-icon" alt="" src={Icon} />
                                <div className={`title-bar-text ${styles.titleBarText}`}>Ambrosius</div>
                            </div>
                            <div className={`window-body ${styles.windowBody}`}>
                                <Toolbar>
                                    <ToolbarButton title="Users" icon={UsersIcon} to="/users" />
                                    <ToolbarDivider />
                                    <ToolbarButton title="Roles" icon={RolesIcon} to="/welcome" disabled />
                                    <ToolbarDivider />
                                    <ToolbarButton title="Apps" icon={AppsIcon} to="/apps" />
                                    <ToolbarDivider />
                                    <ToolbarButton title="Scopes" icon={ScopesIcon} to="/scopes" />
                                    <ToolbarDivider />
                                    <ToolbarButton title="Keywords" icon={KeywordsIcon} to="/keywords" />
                                    <ToolbarDivider />
                                    <ToolbarButton title="ModQ" icon={ModQueueIcon} to="/mod-queue" />
                                    <ToolbarDivider />
                                    <ToolbarButton title="DbView" icon={ObjectsIcon} to="/dbview" />
                                    <ToolbarButton title="Log Off" icon={LogoffIcon} to="/logoff" />
                                </Toolbar>
                                <div className={styles.pageContainer}>
                                    <Outlet />
                                </div>
                                <div className="status-bar">
                                    <p className="status-bar-field">Signed in as: Tasky</p>
                                    <p className={`status-bar-field ${styles.timerStatus}`}>
                                        {expiryWarnState === 'warning' && <img src={WarningIcon} alt="" />}
                                        Session expires in {expiry}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </InputBoxProvider>
            </MessageBoxProvider>
        </SWRConfig>
    );
}

export default Layout;
