import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { ObjectContent, RequestStatus } from 'src/models';
import useCMSFetch from './useCMSFetch';

export default function useModQueue() {
    const [modQ] = useGlobal('modQ');
    const { get, put } = useCMSFetch();

    const setItems = useDispatch(
        (_, items: ObjectContent[]) => ({
            items,
            status: 'done' as RequestStatus,
        }),
        'modQ',
    );

    const setStatus = useDispatch(
        (state, status: RequestStatus) => ({
            ...state,
            status,
        }),
        'modQ',
    );

    const purgeItem = useDispatch(
        (state, itemId: number) => ({
            status: state.status,
            items: state.items.filter(i => i.id !== itemId),
        }),
        'modQ',
    );

    const load = useCallback(async () => {
        try {
            setStatus('loading');
            const response = (await get(`/content/pending`)) as ObjectContent[];

            setItems(response || []);
        } catch (err) {
            setStatus('failed');
        }
    }, [get, setStatus, setItems]);

    const approve = useCallback(
        async (id: number) => {
            try {
                await put(`/content/pending/${id}/approvalStatus`, { isApproved: true });
                await purgeItem(id);
                await load();
            } catch (err) {
                console.error(err);
            }
        },
        [put, load, purgeItem],
    );

    const reject = useCallback(
        async (id: number) => {
            try {
                await put(`/content/pending/${id}/approvalStatus`, { isApproved: false });
                await purgeItem(id);
                await load();
            } catch (err) {
                console.error(err);
            }
        },
        [put, load, purgeItem],
    );

    const { status, items } = modQ;
    const [nextItem] = items;

    return { load, approve, reject, status, nextItem: nextItem as ObjectContent | undefined };
}
