import React from 'react';
import { createProvider } from 'reactn';
import ReactDOM from 'react-dom/client';
import { State } from 'reactn/default';

import INITIAL_STATE from './initial-state';
import App from './App';
import reportWebVitals from './reportWebVitals';

const currentCodeVerifier = window.sessionStorage.getItem('__ambrosius__cv');

const initialState: State = {
    ...INITIAL_STATE,
    codeVerifier: currentCodeVerifier || '',
};

const Provider = createProvider(initialState);
Provider.addCallback(state => {
    window.sessionStorage.setItem('__ambrosius__cv', state.codeVerifier);
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider>
            <App />
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
