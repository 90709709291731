import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { getApiOrigin, getOAuthReplyUrl } from 'src/config';

function OAuthReply() {
    const [, setAccessToken] = useGlobal('accessToken');
    const [, setExpiresAt] = useGlobal('expiresAt');
    const navigate = useNavigate();
    const [codeVerifier, setCodeVerifier] = useGlobal('codeVerifier');
    const location = useLocation();

    const { search } = location;

    useEffect(() => {
        if (search && codeVerifier) {
            const exchangeToken = async () => {
                try {
                    const returnParams = new URLSearchParams(search.substring(1));
                    const requestParams = new URLSearchParams();

                    const code = returnParams.get('code');

                    if (!code) {
                        navigate('/login-error', { replace: true });
                        return;
                    }

                    requestParams.append('grant_type', 'authorization_code');
                    requestParams.append('code', code);
                    requestParams.append('redirect_uri', getOAuthReplyUrl());
                    requestParams.append('client_id', 'reaol_ambrosius');
                    requestParams.append('code_verifier', codeVerifier);

                    const result = await fetch(`${getApiOrigin()}/connect/token`, {
                        method: 'POST',
                        body: requestParams,
                    });

                    if (result.status >= 300) throw new Error(`HTTP ${result.status} ${result.statusText}`);
                    const tokenData = await result.json();

                    if (typeof tokenData.expires_in !== 'number' || typeof tokenData.access_token !== 'string') {
                        navigate('/login-error', { replace: true });
                        return;
                    }

                    const returnUrl = atob(returnParams.get('state') || 'Lw==');
                    const expiry = new Date();
                    expiry.setSeconds(+tokenData.expires_in + expiry.getSeconds() - 60);

                    await setAccessToken(tokenData.access_token);
                    await setExpiresAt(expiry);
                    await setCodeVerifier('');
                    navigate(returnUrl, { replace: true });
                } catch (err) {
                    navigate('/login-error', { replace: true });
                }
            };

            exchangeToken();
        }
    }, [search, codeVerifier, setAccessToken, setExpiresAt, setCodeVerifier, navigate]);

    return <div />;
}

export default OAuthReply;
