/* eslint-disable jsx-a11y/anchor-is-valid */
import AssetViewer from '@components/AssetViewer';
import Toolbar from '@components/Toolbar';
import { useCallback, useState } from 'react';
import { ObjectContent } from 'src/models';
import RecordList from './components/RecordList';
import ViewRecordForm from './components/ViewRecordForm';
import styles from './DbViewLayout.module.css';

function DbViewLayout() {
    const [asset, setAsset] = useState<ObjectContent>();
    const handleSelectAsset = useCallback((newAsset: ObjectContent) => setAsset(newAsset), []);

    return (
        <div className={styles.container}>
            <Toolbar isSecondary>
                <ViewRecordForm />
            </Toolbar>
            <div className={styles.inner}>
                <ul className={`tree-view ${styles.nav}`}>
                    <li>
                        <details>
                            <summary>System Forms</summary>
                            <RecordList type="system-forms" onSelectAsset={handleSelectAsset} />
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>System Assets</summary>
                            <RecordList type="system-ich" onSelectAsset={handleSelectAsset} />
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>UGC Forms</summary>
                            <RecordList type="ugc-forms" onSelectAsset={handleSelectAsset} />
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>UGC Assets</summary>
                            <RecordList type="ugc-ich" onSelectAsset={handleSelectAsset} />
                        </details>
                    </li>
                </ul>
                <div className={styles.outlet}>{asset && <AssetViewer asset={asset} />}</div>
            </div>
        </div>
    );
}

export default DbViewLayout;
