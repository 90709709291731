import { useCallback, useState } from 'react';
import useCMSFetch from './useCMSFetch';
import useMessageBox from './useMessageBox';

function useKeywordDelete() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { del } = useCMSFetch();

    const promptDeleteKeyword = useCallback(
        (keyword: string, onComplete?: (() => void) | undefined) => {
            msgBox({
                title: 'Delete Keyword',
                icon: 'question',
                buttons: ['yes', 'no'],
                children: (
                    <p>
                        Are you sure you want to delete keyword <strong>{keyword}</strong>?
                    </p>
                ),
                onClose: async result => {
                    if (result === 'yes') {
                        setIsBusy(true);

                        try {
                            await del(`/keywords/${keyword}`);
                            onComplete?.();
                        } catch {
                            msgBox({
                                title: 'Error',
                                icon: 'error',
                                buttons: ['ok'],
                                children: <p>There was a problem deleting the keyword. Please try again.</p>,
                            });
                        } finally {
                            setIsBusy(false);
                        }
                    }
                },
            });
        },
        [msgBox, del],
    );

    return { promptDeleteKeyword, isBusy };
}

export default useKeywordDelete;
