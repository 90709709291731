/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useDbViewCursor from '@hooks/useDbViewCursor';
import { useCallback } from 'react';
import { ObjectContent } from 'src/models';

interface Props {
    type: 'system-forms' | 'system-ich' | 'ugc-forms' | 'ugc-ich';
    onSelectAsset: (newAsset: ObjectContent) => void;
}

function RecordList({ type, onSelectAsset }: Props) {
    const { loadNext, items, hasMore, isLoading } = useDbViewCursor(type);
    const handleLoad = useCallback(() => loadNext(), [loadNext]);

    return (
        <ul>
            {items.map(i => (
                <li key={i.id}>
                    <a href="#" onClick={() => onSelectAsset(i)}>
                        {i.globalId}
                    </a>
                </li>
            ))}
            {hasMore && !isLoading && (
                <li>
                    <a href="#" onClick={handleLoad}>
                        Load more
                    </a>
                </li>
            )}
            {isLoading && (
                <li>
                    <em>(loading...)</em>
                </li>
            )}
        </ul>
    );
}

export default RecordList;
