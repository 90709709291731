/* eslint-disable jsx-a11y/label-has-associated-control */
import LoadingOverlay from '@components/LoadingOverlay';
import useKeywordDelete from '@hooks/useKeywordDelete';
import useKeywordsList from '@hooks/useKeywordsList';
import { useCallback } from 'react';
import { PublishedKeyword } from 'src/models';
import styles from './KeywordDetails.module.css';

interface Props {
    keywordDto: PublishedKeyword;
}

function KeywordDetails({ keywordDto }: Props) {
    const { load } = useKeywordsList();
    const { promptDeleteKeyword, isBusy } = useKeywordDelete();
    const { keyword, globalId, ownerScreenName, isSystem } = keywordDto;

    const handleDelete = useCallback(
        () => promptDeleteKeyword(keyword, () => load()),
        [keyword, promptDeleteKeyword, load],
    );

    return (
        <fieldset className={styles.container}>
            <div className={styles.details}>
                <div className={styles.section}>
                    <div>
                        <strong>{keyword}</strong>
                        {isSystem && <em>&nbsp;(system)</em>}
                    </div>
                    {!isSystem && <span>Published by {ownerScreenName}</span>}
                    <span>Linked to {globalId}</span>
                </div>
            </div>
            <fieldset className={styles.actions}>
                <legend>Actions</legend>
                <button type="button" onClick={handleDelete}>
                    Delete
                </button>
            </fieldset>
            {isBusy && <LoadingOverlay />}
        </fieldset>
    );
}

export default KeywordDetails;
