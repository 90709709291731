import Modal from '@components/Modal';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardBanner from '@assets/wizard-banner.png';
import useMessageBox from '@hooks/useMessageBox';
import useKeywordsList from '@hooks/useKeywordsList';
import useCMSFetch from '@hooks/useCMSFetch';
import styles from './KeywordsCreate.module.css';

function KeywordsCreate() {
    const { load } = useKeywordsList();
    const navigate = useNavigate();
    const { put } = useCMSFetch();
    const { msgBox } = useMessageBox();
    const [keyword, setKeyword] = useState('');
    const [objectId, setObjectId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleClose = useCallback(() => navigate(-1), [navigate]);
    const isValid = !!keyword && !!objectId;
    const handleNext = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await put(`/keywords/${keyword}`, {
                globalIdV2: objectId,
            });
            msgBox({
                title: 'Success',
                icon: 'info',
                buttons: ['ok'],
                children: <p>Keyword created!</p>,
                onClose: () => {
                    load().then(() => navigate('/keywords'));
                },
            });
        } catch {
            msgBox({
                title: 'Failed to Create Keyword',
                children: (
                    <p>
                        Failed to create the keyword. Ensure the object ID is valid and the keyword does not already
                        exist.
                    </p>
                ),
                icon: 'error',
                buttons: ['ok'],
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [keyword, objectId, load, navigate, msgBox, put]);

    return (
        <Modal isOpen isSemiTransparent zIndex={5}>
            <div className="window" style={{ cursor: 'default', width: 640, height: 480 }}>
                <div className="title-bar">
                    <div className="title-bar-text">Create New Keyword</div>
                    <div className="title-bar-controls">
                        <button onClick={handleClose} type="button" aria-label="Close" disabled={isSubmitting} />
                    </div>
                </div>
                <div className={`window-body ${styles.windowBody}`}>
                    <div className={styles.contents}>
                        <div className={styles.banner} style={{ backgroundImage: `url(${WizardBanner})` }} />
                        <div className={styles.wizardInner}>
                            <div>
                                <strong>To create a new keyword, please provide the following:</strong>
                            </div>
                            <div className="field-row-stacked" style={{ width: 200 }}>
                                <label htmlFor="keyword">Keyword</label>
                                <input
                                    id="keyword"
                                    type="text"
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="field-row-stacked" style={{ width: 200 }}>
                                <label htmlFor="objectId">Object ID, Format 2 (e.g. 17664-1666)</label>
                                <input
                                    id="objectId"
                                    type="text"
                                    value={objectId}
                                    onChange={e => setObjectId(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className={styles.buttons}>
                        <button type="button" disabled>
                            &lt;&nbsp;&nbsp;Back
                        </button>
                        <button type="button" disabled={!isValid || isSubmitting} onClick={handleNext}>
                            Submit
                        </button>
                        <button type="button" onClick={handleClose} disabled={isSubmitting}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default KeywordsCreate;
