import { ReactElement } from 'react';
import styles from './Toolbar.module.css';

interface Props {
    children: ReactElement | ReactElement[];
    isSecondary?: boolean | undefined;
}

function Toolbar({ children, isSecondary }: Props) {
    return (
        <div
            className={`window ${styles.toolbar} ${isSecondary ? styles.secondary : ''}`}
            data-secondary={isSecondary ? 'yes' : 'no'}
        >
            {children}
        </div>
    );
}

Toolbar.defaultProps = {
    isSecondary: false,
};

export default Toolbar;
