import { useCallback, useEffect } from 'react';
import { AppType, ClientType, ConsentType, Flow } from './model';
import styles from './Step1.module.css';

const AppTypeDisplayName: Record<AppType, string> = {
    Backend: 'Backend Service',
    WebOrMobile: 'Web or Mobile',
};

interface Props {
    appType: AppType;
    displayName: string;
    onDisplayNameChange: (value: string) => void;
    redirectUri: string;
    onRedirectUriChange: (value: string) => void;
    clientType: ClientType | undefined;
    onClientTypeChange: (value: ClientType) => void;
    flows: Flow[];
    onFlowsChange: (value: Flow[]) => void;
    consentType: ConsentType | undefined;
    onConsentTypeChange: (value: ConsentType) => void;
    disabled: boolean;
}

function Step2({
    appType,
    displayName,
    onDisplayNameChange,
    redirectUri,
    onRedirectUriChange,
    clientType,
    onClientTypeChange,
    flows,
    onFlowsChange,
    consentType,
    onConsentTypeChange,
    disabled,
}: Props) {
    const handleSelectFlow = useCallback(
        (flow: Flow, selected: boolean) => {
            if (selected && !flows.includes(flow)) {
                onFlowsChange([...flows, flow]);
            }

            if (!selected && flows.includes(flow)) {
                onFlowsChange(flows.filter(f => f !== flow));
            }
        },
        [flows, onFlowsChange],
    );

    useEffect(() => {
        if (appType === 'Backend' && flows.includes('AuthorizationCode'))
            onFlowsChange(flows.filter(t => t !== 'AuthorizationCode'));

        if (appType === 'Backend' && flows.includes('RefreshToken'))
            onFlowsChange(flows.filter(t => t !== 'RefreshToken'));
    }, [appType, flows, onFlowsChange]);

    useEffect(() => {
        if (clientType === 'public' && flows.includes('ClientCredentials'))
            onFlowsChange(flows.filter(t => t !== 'ClientCredentials'));
    }, [clientType, flows, onFlowsChange]);

    useEffect(() => {
        if (flows.includes('RefreshToken') && !flows.includes('AuthorizationCode') && !flows.includes('DeviceCode')) {
            onFlowsChange(flows.filter(t => t !== 'RefreshToken'));
        }
    }, [clientType, flows, onFlowsChange]);

    return (
        <div className={styles.container}>
            <div>
                <strong>New {AppTypeDisplayName[appType]} App</strong>
            </div>
            <div className="field-row-stacked" style={{ width: 200 }}>
                <label htmlFor="displayName">Display Name</label>
                <input
                    id="displayName"
                    type="text"
                    value={displayName}
                    onChange={e => onDisplayNameChange(e.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="field-row-stacked" style={{ width: 200 }}>
                <label htmlFor="displayName">Redirect URI</label>
                <input
                    id="displayName"
                    type="text"
                    disabled={appType === 'Backend' || disabled}
                    value={redirectUri}
                    onChange={e => onRedirectUriChange(e.target.value)}
                />
            </div>
            <fieldset>
                <legend>Client Type</legend>
                <div className="field-row">
                    <input
                        id="clientTypePublic"
                        type="radio"
                        name="clientType"
                        value="public"
                        disabled={appType === 'Backend' || disabled}
                        checked={clientType === 'public'}
                        onChange={() => onClientTypeChange('public')}
                    />
                    <label htmlFor="clientTypePublic">Public - Client ID Only (no secret)</label>
                </div>
                <div className="field-row">
                    <input
                        id="clientTypeConfidential"
                        type="radio"
                        name="clientType"
                        value="confidential"
                        checked={clientType === 'confidential'}
                        onChange={() => onClientTypeChange('confidential')}
                        disabled={disabled}
                    />
                    <label htmlFor="clientTypeConfidential">Confidential - Client ID + Client Secret</label>
                </div>
            </fieldset>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <fieldset>
                    <legend>Flow(s)</legend>
                    <div className="field-row">
                        <input
                            type="checkbox"
                            id="flowAuthCode"
                            disabled={appType === 'Backend' || disabled}
                            checked={flows.includes('AuthorizationCode')}
                            onChange={e => handleSelectFlow('AuthorizationCode', e.target.checked)}
                        />
                        <label htmlFor="flowAuthCode">Authorization Code</label>
                    </div>
                    <div className="field-row">
                        <input
                            type="checkbox"
                            id="flowDeviceCode"
                            disabled={disabled}
                            checked={flows.includes('DeviceCode')}
                            onChange={e => handleSelectFlow('DeviceCode', e.target.checked)}
                        />
                        <label htmlFor="flowDeviceCode">Device Code</label>
                    </div>
                    <div className="field-row">
                        <input
                            type="checkbox"
                            id="flowRefreshToken"
                            disabled={
                                appType === 'Backend' ||
                                !(flows.includes('AuthorizationCode') || flows.includes('DeviceCode')) ||
                                disabled
                            }
                            checked={flows.includes('RefreshToken')}
                            onChange={e => handleSelectFlow('RefreshToken', e.target.checked)}
                        />
                        <label htmlFor="flowRefreshToken">Refresh Token</label>
                    </div>
                    <div className="field-row">
                        <input
                            type="checkbox"
                            id="flowClientCredentials"
                            disabled={clientType === 'public' || disabled}
                            checked={flows.includes('ClientCredentials')}
                            onChange={e => handleSelectFlow('ClientCredentials', e.target.checked)}
                        />
                        <label htmlFor="flowClientCredentials">Client Credentials</label>
                    </div>
                    <div className="field-row">
                        <input
                            type="checkbox"
                            id="flowPassword"
                            disabled={appType !== 'Backend' || disabled}
                            checked={flows.includes('Password')}
                            onChange={e => handleSelectFlow('Password', e.target.checked)}
                        />
                        <label htmlFor="flowPassword">Password</label>
                    </div>
                </fieldset>
                {(flows.includes('AuthorizationCode') || flows.includes('DeviceCode')) && (
                    <fieldset>
                        <legend>Consent Types</legend>
                        <div className="field-row">
                            <input
                                type="radio"
                                id="consentImplicit"
                                checked={consentType === 'implicit'}
                                onChange={() => onConsentTypeChange('implicit')}
                                disabled={disabled}
                            />
                            <label htmlFor="consentImplicit">Implicit - open, no consent screen</label>
                        </div>
                        <div className="field-row">
                            <input
                                type="radio"
                                id="consentExplicit"
                                checked={consentType === 'explicit'}
                                onChange={() => onConsentTypeChange('explicit')}
                                disabled={disabled}
                            />
                            <label htmlFor="consentExplicit">Explicit - open, user consent required on first use</label>
                        </div>
                        <div className="field-row">
                            <input
                                type="radio"
                                id="consentExternal"
                                checked={consentType === 'external'}
                                onChange={() => onConsentTypeChange('external')}
                                disabled={disabled}
                            />
                            <label htmlFor="consentExternal">External - access granted via Ambrosius</label>
                        </div>
                    </fieldset>
                )}
            </div>
        </div>
    );
}

export default Step2;
