import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useMessageBox from './useMessageBox';

function useAppDelete() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { del } = useFetch();

    const promptDeleteApp = useCallback(
        (id: string, displayName: string, onComplete?: (() => void) | undefined) => {
            msgBox({
                title: 'Delete App',
                icon: 'question',
                buttons: ['yes', 'no'],
                children: <p>Are you sure you want to delete the app {displayName}? This cannot be undone.</p>,
                onClose: async result => {
                    if (result === 'yes') {
                        setIsBusy(true);

                        try {
                            await del(`/apps/${id}`);
                            onComplete?.();
                        } catch {
                            msgBox({
                                title: 'Error',
                                icon: 'error',
                                buttons: ['ok'],
                                children: <p>There was a problem deleting the app. Please try again.</p>,
                            });
                        } finally {
                            setIsBusy(false);
                        }
                    }
                },
            });
        },
        [msgBox, del],
    );

    return { promptDeleteApp, isBusy };
}

export default useAppDelete;
