import { useLocation } from 'react-router-dom';
import LoginRequiredIcon from '@assets/login-required.png';
import { getApiOrigin, getOAuthReplyUrl } from 'src/config';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useGlobal } from 'reactn';
import styles from './Login.module.css';

const getStatePath = (original: string | undefined) => {
    if (!original || original === '/logoff' || original === '/') return '/welcome';
    return original;
};

function Login() {
    const [, setCodeVerifier] = useGlobal('codeVerifier');
    const [authUrl, setAuthUrl] = useState('');
    const location = useLocation();
    const fromPath = (location?.state as any)?.from?.pathname;
    const from = getStatePath(fromPath);

    useEffect(() => {
        if (!from || !setCodeVerifier) return;

        const setUrl = async () => {
            const codeVerifier = [...Array(96)].map(() => Math.random().toString(36)[2]).join('');
            const codeChallenge = CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(codeVerifier));
            const searchParams = new URLSearchParams();
            searchParams.append('client_id', 'reaol_ambrosius');
            searchParams.append('response_type', 'code');
            searchParams.append('prompt', 'login');
            searchParams.append('scope', 'cms:moderation cms:keywords cms:users');
            searchParams.append('redirect_uri', getOAuthReplyUrl());
            searchParams.append('state', btoa(from));
            searchParams.append('code_challenge', codeChallenge);
            searchParams.append('code_challenge_method', 'S256');
            await setCodeVerifier(codeVerifier);

            setAuthUrl(`${getApiOrigin()}/connect/authorize?${searchParams.toString()}`);
        };

        setUrl();
    }, [from, setCodeVerifier]);

    return (
        <div className="window" style={{ alignSelf: 'center', margin: 'auto', width: 300 }}>
            <div className="title-bar">
                <div className="title-bar-text">Authorization Required</div>
            </div>
            <div className="window-body">
                <div className={styles.container}>
                    <img src={LoginRequiredIcon} alt="" />
                    <div className={styles.message}>
                        <p>This page requires authentication. Please sign in with your P3OL account to continue.</p>
                        {authUrl && (
                            <a className={styles.btn} href={authUrl} draggable={false}>
                                Sign In
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
