import useKeywordsList from '@hooks/useKeywordsList';
import styles from './KeywordsList.module.css';
import KeywordDetails from './KeywordDetails';

function KeywordsList() {
    const { items } = useKeywordsList();

    return (
        <div className={styles.container}>
            <div className={styles.results}>
                {items.map(keyword => (
                    <KeywordDetails key={keyword.keyword} keywordDto={keyword} />
                ))}
            </div>
        </div>
    );
}

export default KeywordsList;
