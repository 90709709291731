import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './InputBox.module.css';

interface DropDownOption {
    name: string;
    value: string;
}

interface Props {
    title: string;
    prompt: string;
    dropDownOptions?: DropDownOption[] | undefined;
    onOK?: ((value: string) => void) | undefined;
    onCancel?: (() => void) | undefined;
}

export type IInputBoxProps = Props;

function InputBox({ title, prompt, dropDownOptions, onOK, onCancel }: Props) {
    const textRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');
    const handleOK = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            onOK?.(value);
        },
        [onOK, value],
    );
    const handleCancel = useCallback(() => onCancel?.(), [onCancel]);

    useEffect(() => {
        textRef.current?.focus?.();
    }, [textRef]);

    return (
        <div className="window" style={{ cursor: 'default', height: 150, width: 350 }}>
            <div className="title-bar">
                <div className="title-bar-text">{title}</div>
            </div>
            <form className={`window-body ${styles.body}`} onSubmit={handleOK}>
                <div className={styles.content}>
                    <div className={styles.message}>{prompt}</div>
                    <div className={styles.buttons}>
                        <button type="button" onClick={handleOK}>
                            OK
                        </button>
                        <button type="button" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
                {!dropDownOptions && (
                    <input ref={textRef} type="text" value={value} onChange={e => setValue(e.currentTarget.value)} />
                )}
                {!!dropDownOptions && (
                    <select value={value} onChange={e => setValue(e.currentTarget.value)}>
                        <option>(select)</option>
                        {dropDownOptions.map(opt => (
                            <option key={opt.value} value={opt.value} selected={value === opt.value}>
                                {opt.name}
                            </option>
                        ))}
                    </select>
                )}
            </form>
        </div>
    );
}

InputBox.defaultProps = {
    dropDownOptions: undefined,
    onOK: undefined,
    onCancel: undefined,
};

export default InputBox;
