import AssetViewer from '@components/AssetViewer';
import useModQueue from '@hooks/useModQueue';
import { useCallback } from 'react';
import styles from './ModQueueHome.module.css';

function ModQueueHome() {
    const { approve, reject, status, nextItem } = useModQueue();
    const id = nextItem?.id;
    const handleApprove = useCallback(async () => id && approve(id), [approve, id]);
    const handleReject = useCallback(async () => id && reject(id), [reject, id]);

    if (nextItem) {
        return (
            <div className={styles.item}>
                <AssetViewer asset={nextItem} />
                <fieldset className={styles.actions}>
                    <legend>Actions</legend>
                    <button type="button" onClick={handleApprove}>
                        Approve
                    </button>
                    <button type="button" onClick={handleReject}>
                        Deny
                    </button>
                </fieldset>
            </div>
        );
    }

    if (status === 'idle') return null;
    if (status === 'loading') return <div className={styles.centerMessage}>Loading...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Request failed. Please try again.</div>;

    return <div className={styles.centerMessage}>No content to approve.</div>;
}

export default ModQueueHome;
