import { useGlobal } from 'reactn';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
    children: JSX.Element;
}

function RequireAuth({ children }: Props) {
    const [authToken] = useGlobal('accessToken');
    const location = useLocation();

    if (!authToken) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth;
