import useAppsList from '@hooks/useAppsList';
import AppDetails from './AppDetails';
import styles from './AppsList.module.css';

function AppsList() {
    const { items } = useAppsList();

    return (
        <div className={styles.container}>
            <div className={styles.results}>
                {items.map(app => (
                    <AppDetails key={app.id} app={app} />
                ))}
            </div>
        </div>
    );
}

export default AppsList;
