import useAppsList from '@hooks/useAppsList';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './AppDetailsPage.module.css';
import AppDetails from './components/AppDetails';

function AppDetailsPage() {
    const { appId } = useParams();
    const { status, items, load } = useAppsList();
    const app = items?.find(a => a.id === appId);

    useEffect(() => {
        if (status === 'idle') load();
    }, [status, load]);

    if (status === 'idle') return null;
    if (status === 'loading' && items.length === 0) return <div className={styles.centerMessage}>Loading...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Request failed. Please try again.</div>;
    if (status === 'done' && !app) return <div className={styles.centerMessage}>App not found.</div>;

    return (
        <div className={styles.container}>
            <div className={styles.results}>
                <AppDetails app={app} />
            </div>
        </div>
    );
}

export default AppDetailsPage;
