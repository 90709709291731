import Modal from '@components/Modal';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardBanner from '@assets/wizard-banner.png';
import useFetch from '@hooks/useFetch';
import useMessageBox from '@hooks/useMessageBox';
import useScopesList from '@hooks/useScopesList';
import styles from './ScopeCreateDialog.module.css';

function ScopeCreateDialog() {
    const { load } = useScopesList();
    const navigate = useNavigate();
    const { post } = useFetch();
    const { msgBox } = useMessageBox();
    const [name, setName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleClose = useCallback(() => navigate(-1), [navigate]);
    const isValid = !!name && !!displayName && !!description;
    const handleNext = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await post('/scopes', {
                name,
                displayName,
                description,
            });
            msgBox({
                title: 'Success',
                icon: 'info',
                buttons: ['ok'],
                children: <p>Scope created!</p>,
                onClose: () => {
                    load().then(() => navigate('/scopes'));
                },
            });
        } catch {
            msgBox({
                title: 'Failed to Create Scope',
                children: <p>Failed to create the scope. Please try again later or reach out to a dev.</p>,
                icon: 'error',
                buttons: ['ok'],
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [name, displayName, description, load, navigate, msgBox, post]);

    return (
        <Modal isOpen isSemiTransparent zIndex={5}>
            <div className="window" style={{ cursor: 'default', width: 640, height: 480 }}>
                <div className="title-bar">
                    <div className="title-bar-text">Create New Scope</div>
                    <div className="title-bar-controls">
                        <button onClick={handleClose} type="button" aria-label="Close" disabled={isSubmitting} />
                    </div>
                </div>
                <div className={`window-body ${styles.windowBody}`}>
                    <div className={styles.contents}>
                        <div className={styles.banner} style={{ backgroundImage: `url(${WizardBanner})` }} />
                        <div className={styles.wizardInner}>
                            <div>
                                <strong>To create a new scope, please provide the following:</strong>
                            </div>
                            <div className="field-row-stacked" style={{ width: 200 }}>
                                <label htmlFor="name">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="field-row-stacked" style={{ width: 200 }}>
                                <label htmlFor="displayName">Display Name</label>
                                <input
                                    id="displayName"
                                    type="text"
                                    value={displayName}
                                    onChange={e => setDisplayName(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="field-row-stacked" style={{ width: 200 }}>
                                <label htmlFor="description">Description</label>
                                <input
                                    id="description"
                                    type="text"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className={styles.buttons}>
                        <button type="button" disabled>
                            &lt;&nbsp;&nbsp;Back
                        </button>
                        <button type="button" disabled={!isValid || isSubmitting} onClick={handleNext}>
                            Submit
                        </button>
                        <button type="button" onClick={handleClose} disabled={isSubmitting}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ScopeCreateDialog;
