import { useEffect, useRef } from 'react';
import { useGlobal } from 'reactn';
import { RoleDetails } from 'src/models';
import useFetch from './useFetch';

function useRoles() {
    const hasLoaded = useRef(false);
    const [{ status, items }, setRoles] = useGlobal('roles');
    const { get } = useFetch();

    useEffect(() => {
        if (!get || !setRoles || hasLoaded.current || status === 'done') return;

        const loadRoles = async () => {
            hasLoaded.current = true;
            setRoles({
                status: 'loading',
                items,
            });

            try {
                const result = await get<RoleDetails[]>(`/roles`);
                setRoles({
                    status: 'done',
                    items: result || [],
                });
            } catch {
                setRoles({
                    status: 'failed',
                    items,
                });
            }
        };

        loadRoles();
    }, [get, setRoles, status, items]);

    return { roles: items, status };
}

export default useRoles;
