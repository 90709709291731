import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';
import useRoles from './useRoles';

function useUserAddRole() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { roles } = useRoles();
    const { post } = useFetch();

    const promptAddRole = useCallback(
        (userId: string, onComplete: (() => void) | undefined) => {
            if (roles.length === 0) return;

            inputBox({
                title: 'Add Role',
                prompt: 'Pick the role to add to the user:',
                dropDownOptions: roles.map(r => ({ name: r.name, value: r.id })),
                onOK: async roleId => {
                    try {
                        setIsBusy(true);
                        await post(`/users/${userId}/roles`, { roleId });
                        onComplete?.();
                    } catch (err) {
                        msgBox({
                            title: 'Error',
                            icon: 'error',
                            buttons: ['ok'],
                            children: <p>There was a problem adding the user to the role. Please try again.</p>,
                        });
                    } finally {
                        setIsBusy(false);
                    }
                },
            });
        },
        [roles, inputBox, msgBox, post],
    );

    return { canAddRole: roles.length > 0, promptAddRole, isBusy };
}

export default useUserAddRole;
