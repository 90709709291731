import InputBox, { IInputBoxProps } from '@components/InputBox';
import Modal from '@components/Modal';
import { createContext, ReactElement, useCallback, useContext, useMemo, useState } from 'react';

interface IInputBoxContext {
    inputBox: (params: IInputBoxProps) => void;
}

const InputBoxContextInitial: IInputBoxContext = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    inputBox: () => {},
};

const InputBoxContext = createContext(InputBoxContextInitial);

interface Props {
    children: ReactElement;
}

export function InputBoxProvider({ children }: Props) {
    const [current, setCurrent] = useState<IInputBoxProps | undefined>();

    const handleShowInputBox = useCallback(
        (params: IInputBoxProps) => {
            if (current) throw new Error('Cannot show more than one input box at a time');
            setCurrent(params);
        },
        [current],
    );

    const handleOK = useCallback(
        (value: string) => {
            current?.onOK?.(value);
            setCurrent(undefined);
        },
        [current],
    );

    const handleCancel = useCallback(() => {
        current?.onCancel?.();
        setCurrent(undefined);
    }, [current]);

    const value = useMemo(() => ({ inputBox: handleShowInputBox }), [handleShowInputBox]);
    return (
        <InputBoxContext.Provider value={value}>
            <>
                {current && (
                    <Modal isOpen>
                        <InputBox {...current} onOK={handleOK} onCancel={handleCancel} />
                    </Modal>
                )}
                {children}
            </>
        </InputBoxContext.Provider>
    );
}

export default function useInputBox() {
    const context = useContext(InputBoxContext);

    if (context === undefined) {
        throw new Error('useInputBox must be used within a InputBoxProvider');
    }

    return context;
}
