import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useMessageBox from './useMessageBox';

function useAppDeleteScope() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { del } = useFetch();

    const promptDeleteScope = useCallback(
        (appId: string, scopeId: string, scopeName: string, onComplete?: (() => void) | undefined) => {
            msgBox({
                title: 'Remove Scope from App',
                icon: 'question',
                buttons: ['yes', 'no'],
                children: (
                    <p>
                        Are you sure you want to remove the <strong>{scopeName}</strong>?<br />
                        This can have unintended consequences.
                    </p>
                ),
                onClose: async result => {
                    if (result === 'yes') {
                        setIsBusy(true);

                        try {
                            await del(`/apps/${appId}/scopes/${scopeId}`);
                            onComplete?.();
                        } catch {
                            msgBox({
                                title: 'Error',
                                icon: 'error',
                                buttons: ['ok'],
                                children: (
                                    <p>There was a problem removing the scope from this app. Please try again.</p>
                                ),
                            });
                        } finally {
                            setIsBusy(false);
                        }
                    }
                },
            });
        },
        [msgBox, del],
    );

    return { promptDeleteScope, isBusy };
}

export default useAppDeleteScope;
