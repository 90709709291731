import { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useSound } from 'use-sound';
import ClickSound from '@assets/start.wav';

function AudibleLink({ ...props }: LinkProps) {
    const [play] = useSound(ClickSound);
    const handleClick = useCallback(() => play(), [play]);

    return <Link {...props} onClick={handleClick} />;
}

export default AudibleLink;
