import useScopesList from '@hooks/useScopesList';
import ScopeDetails from './ScopeDetails';
import styles from './ScopesList.module.css';

function ScopesList() {
    const { items } = useScopesList();

    return (
        <div className={styles.container}>
            <div className={styles.results}>
                {items.map(scope => (
                    <ScopeDetails key={scope.id} scope={scope} />
                ))}
            </div>
        </div>
    );
}

export default ScopesList;
