import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { PublishedKeyword, RequestStatus } from 'src/models';
import useCMSFetch from './useCMSFetch';

export default function useKeywordsList() {
    const [keywords] = useGlobal('keywords');
    const { get } = useCMSFetch();

    const setItems = useDispatch(
        (_, items: PublishedKeyword[]) => ({
            status: 'done' as RequestStatus,
            items,
        }),
        'keywords',
    );

    const setStatus = useDispatch(
        (state, status: RequestStatus) => ({
            items: state.items,
            status,
        }),
        'keywords',
    );

    const load = useCallback(async () => {
        try {
            setStatus('loading');
            const response = (await get(`/keywords`)) as PublishedKeyword[];

            setItems(response);
        } catch (err) {
            setStatus('failed');
        }
    }, [get, setStatus, setItems]);

    const { status, items } = keywords;

    return { load, status, items };
}
