import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { RequestStatus, UserDetail, UserUGCDetail } from 'src/models';
import useCMSFetch from './useCMSFetch';
import useFetch from './useFetch';

export default function useUsersSearch() {
    const [users] = useGlobal('users');
    const { get } = useFetch();
    const { get: ugcGet } = useCMSFetch();

    const setQuery = useDispatch((global, _, query: string) => ({
        users: {
            ...global.users,
            search: {
                ...global.users.search,
                query,
                status: 'loading' as RequestStatus,
                results: [],
            },
            ugc: {},
        },
    }));
    const setResults = useDispatch((global, _, results: UserDetail[], hasMore: boolean) => ({
        users: {
            ...global.users,
            search: {
                ...global.users.search,
                status: 'done' as RequestStatus,
                results,
                hasMore,
            },
        },
    }));
    const setFailed = useDispatch(global => ({
        users: {
            ...global.users,
            search: {
                ...global.users.search,
                status: 'failed' as RequestStatus,
            },
        },
    }));
    const setUserUGC = useDispatch(
        (state, screenName: string, ugcDetail: UserUGCDetail) => ({
            search: state.search,
            ugc: {
                ...state.ugc,
                [screenName]: ugcDetail,
            },
        }),
        'users',
    );

    const updateUser = useDispatch((global, _, user: UserDetail) => ({
        users: {
            ...global.users,
            search: {
                ...global.users.search,
                results: [
                    ...global.users.search.results.slice(
                        0,
                        global.users.search.results.findIndex(d => d.id === user.id),
                    ),
                    user,
                    ...global.users.search.results.slice(
                        global.users.search.results.findIndex(d => d.id === user.id) + 1,
                    ),
                ],
            },
        },
    }));

    const search = useCallback(
        async (query: string) => {
            await setQuery(query);
            try {
                const response = (await get(`/users/search`, new URLSearchParams({ query }))) as {
                    results: UserDetail[];
                    hasMore: boolean;
                };

                setResults(response.results, response.hasMore);

                await Promise.all(
                    response.results.map(async user => {
                        try {
                            const userUGC = await ugcGet<UserUGCDetail>(`/users/${user.screenName}`);

                            if (userUGC) {
                                await setUserUGC(user.screenName, userUGC);
                            }
                        } catch (err) {
                            console.error('Failed to lookup user UGC data', err);
                        }
                    }),
                );
            } catch (err) {
                setFailed();
            }
        },
        [get, ugcGet, setQuery, setResults, setFailed, setUserUGC],
    );

    const reloadUser = useCallback(
        async (userId: string) => {
            try {
                const response = (await get(`/users/${userId}`)) as UserDetail;
                await updateUser(response);
            } catch {
                console.error('Error fetching user details');
            }
        },
        [get, updateUser],
    );

    const { status, results, hasMore } = users.search;

    return { search, reloadUser, status, results, hasMore };
}
