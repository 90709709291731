import { useEffect } from 'react';
import useScopesList from '@hooks/useScopesList';
import styles from './ScopesHome.module.css';
import ScopesList from './components/ScopesList';

function ScopesHome() {
    const { status, items, load } = useScopesList();

    useEffect(() => {
        if (status === 'idle') load();
    }, [status, load]);

    if (status === 'idle') return null;
    if (status === 'loading' && items.length === 0) return <div className={styles.centerMessage}>Loading...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Request failed. Please try again.</div>;
    if (status === 'done' && items.length === 0) return <div className={styles.centerMessage}>No scopes found.</div>;

    return <ScopesList />;
}

export default ScopesHome;
