import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { ObjectReference, RequestStatus } from 'src/models';
import useFetch from './useFetch';

export default function useAppPermissions() {
    const [permissions] = useGlobal('app_permissions');
    const { get } = useFetch();

    const setItems = useDispatch((_, __, items: ObjectReference[]) => ({
        app_permissions: {
            status: 'done' as RequestStatus,
            items,
        },
    }));

    const setStatus = useDispatch((global, _, status: RequestStatus) => ({
        app_permissions: {
            ...global.app_permissions,
            status,
        },
    }));

    const load = useCallback(async () => {
        try {
            setStatus('loading');
            const response = (await get(`/apps/permissions`)) as ObjectReference[];

            setItems(response);
        } catch (err) {
            setStatus('failed');
        }
    }, [get, setStatus, setItems]);

    const { status, items } = permissions;
    return { load, status, items };
}
