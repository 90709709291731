import LoginRequiredIcon from '@assets/login-required.png';
import { Link } from 'react-router-dom';
import styles from './Login.module.css';

function LoginError() {
    return (
        <div className="window" style={{ alignSelf: 'center', margin: 'auto', width: 300 }}>
            <div className="title-bar">
                <div className="title-bar-text">Authorization Failed</div>
            </div>
            <div className="window-body">
                <div className={styles.container}>
                    <img src={LoginRequiredIcon} alt="" />
                    <div className={styles.message}>
                        <p>There was a problem signing you in.</p>
                        <Link className={styles.btn} to="/login" draggable={false}>
                            Try Again
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginError;
