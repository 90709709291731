import { Outlet } from 'react-router-dom';
import UserAddIcon from '@assets/toolbar/user-add.png';
import UserImportIcon from '@assets/toolbar/user-import.png';
import Toolbar from '@components/Toolbar';
import ToolbarDivider from '@components/ToolbarDivider';
import ToolbarButton from '@components/ToolbarButton';
import { useCallback } from 'react';
import LoadingOverlay from '@components/LoadingOverlay';
import useUsersSearch from '@hooks/useUsersSearch';
import useUserCreate from '@hooks/useUserCreate';
import useUserImport from '@hooks/useUserImport';
import ProgressBar from '@components/ProgressBar';
import UsersSearchForm from './components/UsersSearchForm';
import styles from './UsersLayout.module.css';

function UsersLayout() {
    const { search } = useUsersSearch();
    const onCreated = useCallback((screenName: string) => search(screenName), [search]);
    const { promptCreateUser, isCreatingUser } = useUserCreate({ onCreated });
    const { promptImport, isImporting, importProgress } = useUserImport();

    return (
        <div className={styles.container}>
            <Toolbar isSecondary>
                <UsersSearchForm />
                <ToolbarDivider />
                <ToolbarButton title="New User" icon={UserAddIcon} onClick={promptCreateUser} />
                <ToolbarDivider />
                <ToolbarButton title="Import Users" icon={UserImportIcon} onClick={promptImport} />
            </Toolbar>
            <div className={styles.outlet}>
                <Outlet />
                {isImporting && (
                    <LoadingOverlay>
                        <div className="window" style={{ cursor: 'default', height: 60, width: 350 }}>
                            <div className="title-bar">
                                <div className="title-bar-text">Importing Users</div>
                            </div>
                            <div className="window-body">
                                <ProgressBar progress={importProgress} />
                            </div>
                        </div>
                    </LoadingOverlay>
                )}
            </div>
            {isCreatingUser && <LoadingOverlay />}
        </div>
    );
}

export default UsersLayout;
