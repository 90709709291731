import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';
import { ObjectReference, RequestStatus } from 'src/models';
import useFetch from './useFetch';

export default function useAppScopes() {
    const [scopes] = useGlobal('app_scopes');
    const { get } = useFetch();

    const setItems = useDispatch((_, __, items: ObjectReference[]) => ({
        app_scopes: {
            status: 'done' as RequestStatus,
            items,
        },
    }));

    const setStatus = useDispatch((global, _, status: RequestStatus) => ({
        app_scopes: {
            ...global.app_scopes,
            status,
        },
    }));

    const load = useCallback(async () => {
        try {
            setStatus('loading');
            const response = (await get(`/apps/scopes`)) as ObjectReference[];

            setItems(response);
        } catch (err) {
            setStatus('failed');
        }
    }, [get, setStatus, setItems]);

    const { status, items } = scopes;
    return { load, status, items };
}
