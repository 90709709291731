import { Outlet } from 'react-router-dom';
import Toolbar from '@components/Toolbar';
import ToolbarButton from '@components/ToolbarButton';
import { useCallback } from 'react';
import ToolbarDivider from '@components/ToolbarDivider';
import KeywordAddIcon from '@assets/toolbar/keyword-add.png';
import useKeywordsList from '@hooks/useKeywordsList';
import styles from './KeywordsLayout.module.css';

function KeywordsLayout() {
    const { load } = useKeywordsList();
    const handleRefresh = useCallback(() => {
        load();
    }, [load]);

    return (
        <div className={styles.container}>
            <Toolbar isSecondary>
                <ToolbarButton title="Refresh" onClick={handleRefresh} />
                <ToolbarDivider />
                <ToolbarButton title="New Keyword" icon={KeywordAddIcon} to="/keywords/create" />
            </Toolbar>
            <div className={styles.outlet}>
                <Outlet />
            </div>
        </div>
    );
}

export default KeywordsLayout;
