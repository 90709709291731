import { ReactElement } from 'react';
import styles from './Modal.module.css';

interface Props {
    children: ReactElement;
    isOpen: boolean;
    isSemiTransparent?: boolean | undefined;
    zIndex?: number | undefined;
}

function Modal({ children, isOpen, isSemiTransparent, zIndex }: Props) {
    if (!isOpen) return null;

    return (
        <div className={`${styles.backdrop} ${isSemiTransparent ? styles.semiTransparent : ''}`} style={{ zIndex }}>
            <div className={styles.contents}>{children}</div>
        </div>
    );
}

Modal.defaultProps = {
    isSemiTransparent: false,
    zIndex: undefined,
};

export default Modal;
