const getApiOrigin = () => {
    if (window.location.host === 'ambrosius-staging.p3ol.ca') {
        return 'https://my-staging.p3ol.ca';
    }
    if (window.location.host === 'ambrosius.p3ol.ca') {
        return 'https://my.p3ol.ca';
    }
    if (window.location.host === 'host.docker.internal:8011') {
        return 'http://host.docker.internal:8010';
    }

    return 'http://localhost:5016';
};

const getCMSApiOrigin = () => {
    if (window.location.host === 'ambrosius-staging.p3ol.ca') {
        return 'https://cms-staging.p3ol.ca';
    }
    if (window.location.host === 'ambrosius.p3ol.ca') {
        return 'https://cms.p3ol.ca';
    }
    if (window.location.host === 'host.docker.internal:8011') {
        return 'http://host.docker.internal:8015';
    }

    return 'http://localhost:5067';
};

const getOAuthReplyUrl = () => `${window.location.origin}/oauth-reply`;

export { getApiOrigin, getCMSApiOrigin, getOAuthReplyUrl };
