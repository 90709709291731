/* eslint-disable jsx-a11y/label-has-associated-control */
import LoadingOverlay from '@components/LoadingOverlay';
import useUserAddAuthorization from '@hooks/useUserAddAuthorization';
import useUserAddRole from '@hooks/useUserAddRole';
import useUserDeleteAuthorization from '@hooks/useUserDeleteAuthorization';
import useUserDeleteRole from '@hooks/useUserDeleteRole';
import useUserLock from '@hooks/useUserLock';
import useUserPasswordReset from '@hooks/useUserPasswordReset';
import useUsersSearch from '@hooks/useUsersSearch';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { UserDetail } from 'src/models';
import styles from './UserDetails.module.css';
import UserUGCDetails from './UserUGCDetails';

interface Props {
    user: UserDetail;
}

function UserDetails({ user }: Props) {
    const { reloadUser } = useUsersSearch();
    const { promptAddRole, canAddRole, isBusy: isAddBusy } = useUserAddRole();
    const { promptAddAuthorization, canAddAuthorization, isBusy: isAddAuthzBusy } = useUserAddAuthorization();
    const { promptDeleteAuthorization, isBusy: isDeleteAuthzBusy } = useUserDeleteAuthorization();
    const { promptDeleteRole, isBusy: isDeleteBusy } = useUserDeleteRole();
    const { lockUser, unlockUser, isBusy: isLockBusy } = useUserLock();
    const { promptPasswordReset, isBusy: isResetBusy } = useUserPasswordReset();

    const { id, screenName, roles, authorizedApps, ownedApps, isLockedOut } = user;
    const handleAddRole = useCallback(() => promptAddRole(id, () => reloadUser(id)), [id, promptAddRole, reloadUser]);
    const handleAddApp = useCallback(
        () => promptAddAuthorization(id, () => reloadUser(id)),
        [id, promptAddAuthorization, reloadUser],
    );
    const handleDeleteRole = useCallback(
        (roleId: string, roleName: string) => promptDeleteRole(id, screenName, roleId, roleName, () => reloadUser(id)),
        [id, screenName, promptDeleteRole, reloadUser],
    );
    const handleDeleteAuthz = useCallback(
        (appId: string, appName: string) =>
            promptDeleteAuthorization(id, screenName, appId, appName, () => reloadUser(id)),
        [id, screenName, promptDeleteAuthorization, reloadUser],
    );
    const handleLock = useCallback(() => lockUser(id, () => reloadUser(id)), [id, lockUser, reloadUser]);
    const handleUnlock = useCallback(() => unlockUser(id, () => reloadUser(id)), [id, unlockUser, reloadUser]);
    const handleReset = useCallback(
        () => promptPasswordReset(id, screenName, () => reloadUser(id)),
        [id, screenName, promptPasswordReset, reloadUser],
    );

    const isBusy = isAddBusy || isAddAuthzBusy || isDeleteBusy || isDeleteAuthzBusy || isLockBusy || isResetBusy;

    return (
        <fieldset className={styles.container}>
            <div className={styles.details}>
                <div className={styles.section}>
                    <strong>{screenName}</strong>
                    {roles.length === 0 && <em>No roles assigned.</em>}
                    {roles.length > 0 && (
                        <div className={styles.list}>
                            {roles.map(role => (
                                <div key={role.id} className={styles.listItem}>
                                    <span>{role.name}</span>
                                    <Link to={`/roles/${role.id}`}>View</Link>
                                    <button
                                        className="link"
                                        type="button"
                                        onClick={() => handleDeleteRole(role.id, role.name)}
                                    >
                                        Eject
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <strong>Authorized Apps</strong>
                    {authorizedApps.length === 0 && <em>No authorized apps.</em>}
                    {authorizedApps.length > 0 && (
                        <div className={styles.list}>
                            {authorizedApps.map(app => (
                                <div key={app.id} className={styles.listItem}>
                                    <span>{app.name}</span>
                                    <Link to={`/apps/${app.id}`}>View</Link>
                                    <button
                                        className="link"
                                        type="button"
                                        onClick={() => handleDeleteAuthz(app.id, app.name)}
                                    >
                                        Revoke
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <strong>Owned Apps</strong>
                    {ownedApps.length === 0 && <em>No owned apps.</em>}
                    {ownedApps.length > 0 && (
                        <div className={styles.list}>
                            {ownedApps.map(app => (
                                <div key={app.id} className={styles.listItem}>
                                    <span>{app.name}</span>
                                    <Link to={`/apps/${app.id}`}>View</Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.section}>
                    <UserUGCDetails screenName={screenName} />
                </div>
            </div>
            <fieldset className={styles.actions}>
                <legend>Actions</legend>
                {/* <button type="button">Send OSW</button> */}
                <button type="button" disabled={!canAddRole} onClick={handleAddRole}>
                    Add Role
                </button>
                <button type="button" disabled={!canAddAuthorization} onClick={handleAddApp}>
                    Add App
                </button>
                <button type="button" onClick={handleReset}>
                    Reset PW
                </button>
                {!isLockedOut && (
                    <button type="button" onClick={handleLock}>
                        Lock
                    </button>
                )}
                {isLockedOut && (
                    <button type="button" onClick={handleUnlock}>
                        Unlock
                    </button>
                )}
                {/* <button type="button">Kill Tokens</button> */}
            </fieldset>
            {isBusy && <LoadingOverlay />}
        </fieldset>
    );
}

export default UserDetails;
