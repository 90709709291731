import { Outlet } from 'react-router-dom';
import AppAddIcon from '@assets/toolbar/app-add.png';
import Toolbar from '@components/Toolbar';
import ToolbarButton from '@components/ToolbarButton';
import { useCallback } from 'react';
import useAppsList from '@hooks/useAppsList';
import ToolbarDivider from '@components/ToolbarDivider';
import useAppScopes from '@hooks/useAppScopes';
import styles from './AppsLayout.module.css';

function AppsLayout() {
    const { load: loadApps } = useAppsList();
    const { load: loadScopes } = useAppScopes();
    const handleRefresh = useCallback(() => {
        loadApps();
        loadScopes();
    }, [loadApps, loadScopes]);

    return (
        <div className={styles.container}>
            <Toolbar isSecondary>
                <ToolbarButton title="Refresh" onClick={handleRefresh} />
                <ToolbarDivider />
                <ToolbarButton title="New App" icon={AppAddIcon} to="/apps/create" />
            </Toolbar>
            <div className={styles.outlet}>
                <Outlet />
            </div>
        </div>
    );
}

export default AppsLayout;
