import InfoIcon from '@assets/messagebox/info.png';
import QuestionIcon from '@assets/messagebox/question.png';
import WarningIcon from '@assets/messagebox/warning.png';
import ErrorIcon from '@assets/messagebox/error.png';
import { ReactElement, useCallback } from 'react';
import styles from './MessageBox.module.css';

export type MessageBoxIcon = 'info' | 'question' | 'warning' | 'error';
export type MessageBoxButton = 'ok' | 'cancel' | 'yes' | 'no' | 'abort' | 'retry';

const IconMap: Record<MessageBoxIcon, string> = {
    info: InfoIcon,
    question: QuestionIcon,
    warning: WarningIcon,
    error: ErrorIcon,
};

interface Props {
    children?: ReactElement | undefined;
    title: string;
    icon: MessageBoxIcon;
    buttons: MessageBoxButton[];
    onClose?: ((result: MessageBoxButton) => void) | undefined;
}

export type IMessageBoxProps = Props;

function MessageBox({ title, icon, buttons, onClose, children }: Props) {
    const handleClose = useCallback(
        (button: MessageBoxButton) => {
            if (onClose) onClose(button);
        },
        [onClose],
    );

    return (
        <div className="window" style={{ cursor: 'default' }}>
            <div className="title-bar">
                <div className="title-bar-text">{title}</div>
            </div>
            <div className={`window-body ${styles.body}`}>
                <div className={styles.content}>
                    <div className={styles.icon}>
                        <img src={IconMap[icon]} alt={icon} />
                    </div>
                    <div className={styles.message}>{children}</div>
                </div>
                <div className={styles.buttons}>
                    {buttons.includes('ok') && (
                        <button type="button" onClick={() => handleClose('ok')}>
                            OK
                        </button>
                    )}
                    {buttons.includes('yes') && (
                        <button type="button" onClick={() => handleClose('yes')}>
                            Yes
                        </button>
                    )}
                    {buttons.includes('no') && (
                        <button type="button" onClick={() => handleClose('no')}>
                            No
                        </button>
                    )}
                    {buttons.includes('abort') && (
                        <button type="button" onClick={() => handleClose('abort')}>
                            Abort
                        </button>
                    )}
                    {buttons.includes('retry') && (
                        <button type="button" onClick={() => handleClose('retry')}>
                            Retry
                        </button>
                    )}
                    {buttons.includes('cancel') && (
                        <button type="button" onClick={() => handleClose('cancel')}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

MessageBox.defaultProps = {
    children: undefined,
    onClose: undefined,
};

export default MessageBox;
