import useUserUGCDetail from '@hooks/useUserUGCDetail';
import { formatBytes } from 'src/util';
import styles from './UserUGCDetails.module.css';

interface Props {
    screenName: string;
}

function UserUGCDetails({ screenName }: Props) {
    const ugcDetails = useUserUGCDetail(screenName);

    if (!ugcDetails) {
        return (
            <>
                <strong>UGC</strong>
                <em>Loading...</em>
            </>
        );
    }

    const {
        isBanned,
        hasUnapprovedContent,
        objectsUsed,
        objectQuota,
        objectsRemaining,
        bytesUsed,
        byteQuota,
        bytesRemaining,
    } = ugcDetails;

    return (
        <>
            <strong>UGC</strong>
            {isBanned && <em>User is banned from publishing UGC.</em>}
            {hasUnapprovedContent && <em>User has content in ModQ.</em>}
            <div className={styles.list}>
                <div className={styles.listItem}>
                    <span>
                        Objects: {objectsUsed} / {objectQuota}
                    </span>
                    &nbsp;
                    <span>({objectsRemaining} remaining)</span>
                </div>
                <div className={styles.listItem}>
                    <span>
                        Data: {formatBytes(bytesUsed)} / {formatBytes(byteQuota || 0)}
                    </span>
                    &nbsp;
                    <span>({formatBytes(bytesRemaining || 0)} remaining)</span>
                </div>
            </div>
        </>
    );
}

export default UserUGCDetails;
