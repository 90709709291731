function Welcome() {
    return (
        <div>
            <strong>Welcome to Ambrosius</strong>
            <p>Please use this system responsibly.</p>
        </div>
    );
}

export default Welcome;
