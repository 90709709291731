/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useCallback, useState } from 'react';
import Papa from 'papaparse';
import useMessageBox from './useMessageBox';
import useFetch from './useFetch';

export default function useUserImport() {
    const { msgBox } = useMessageBox();
    const [isImporting, setIsImporting] = useState(false);
    const [importProgress, setImportProgress] = useState(0);
    const { post } = useFetch();

    const promptImport = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.csv');
        input.addEventListener('change', async () => {
            if (!input.files?.length) return;

            const csvData = await input.files[0].text();
            const parsed = Papa.parse<string[]>(csvData, { download: false });

            if (parsed.errors.length > 0) {
                msgBox({
                    title: 'Import Failed',
                    icon: 'error',
                    buttons: ['ok'],
                    children: <p>Failed to parse CSV.</p>,
                });
            }

            const errors: string[] = [];

            for (const userPass of parsed.data) {
                if (userPass[0].length < 3 || userPass[0].length > 10) {
                    errors.push(`${userPass[0]} is not a valid Screen Name.`);
                }

                if (userPass[1].length < 6) {
                    errors.push(`${userPass[0]} does not have a valid password.`);
                }
            }

            if (errors.length > 0) {
                msgBox({
                    title: 'Import Failed',
                    icon: 'error',
                    buttons: ['ok'],
                    children: (
                        <>
                            {errors.map((e, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={idx}>{e}</p>
                            ))}
                        </>
                    ),
                });
            }

            setIsImporting(true);
            let imported = 0;

            for (const userPass of parsed.data) {
                try {
                    imported += 1;
                    setImportProgress((imported * 100) / parsed.data.length);
                    await post(`/users`, { screenName: userPass[0], password: userPass[1] });
                } catch {
                    errors.push(`Failed to import Screen Name: ${userPass[0]}`);
                }
            }

            setIsImporting(false);

            if (errors.length > 0) {
                msgBox({
                    title: 'Import Partially Failed',
                    icon: 'error',
                    buttons: ['ok'],
                    children: (
                        <>
                            {errors.map((e, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={idx}>{e}</p>
                            ))}
                        </>
                    ),
                });
            } else {
                msgBox({
                    title: 'Import Successful',
                    icon: 'info',
                    buttons: ['ok'],
                    children: <p>Imported ${parsed.data.length} screen names successfully.</p>,
                });
            }
        });
        input.click();
    }, [msgBox, post]);

    return { promptImport, isImporting, importProgress };
}
