import WarningIcon from '@assets/warning16.png';
import useUsersSearch from '@hooks/useUsersSearch';
import UserDetails from './UserDetails';
import styles from './UsersSearchResults.module.css';

function UsersSearchResults() {
    const { results, hasMore } = useUsersSearch();

    return (
        <div className={styles.container}>
            {hasMore && (
                <div className={styles.banner}>
                    <img src={WarningIcon} alt="" />
                    <span>More than 50 results were found. Please use a more specific query.</span>
                </div>
            )}
            <div className={styles.results}>
                {results.map(user => (
                    <UserDetails key={user.id} user={user} />
                ))}
            </div>
        </div>
    );
}

export default UsersSearchResults;
