import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useInputBox from './useInputBox';
import useMessageBox from './useMessageBox';

interface Props {
    onCreated?: ((screenName: string) => void) | undefined;
}

function useUserCreate({ onCreated }: Props) {
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const { msgBox } = useMessageBox();
    const { inputBox } = useInputBox();
    const { post } = useFetch();

    const promptCreateUser = useCallback(() => {
        inputBox({
            title: 'New User',
            prompt: 'Enter a screen name for the new user:',
            onOK: async screenName => {
                try {
                    setIsCreatingUser(true);
                    const result = (await post(`/users`, { screenName })) as { screenName: string; password: string };
                    msgBox({
                        title: 'Success',
                        icon: 'info',
                        buttons: ['ok'],
                        children: (
                            <>
                                <p>Screen name created! Please copy the password as it will not be displayed again.</p>
                                <pre>{result.password}</pre>
                            </>
                        ),
                    });
                    onCreated?.(screenName);
                } catch (err) {
                    msgBox({
                        title: 'Error',
                        icon: 'error',
                        buttons: ['ok'],
                        children: (
                            <p>
                                There was a problem creating the user. Please check that the screen name is valid and
                                available.
                            </p>
                        ),
                    });
                } finally {
                    setIsCreatingUser(false);
                }
            },
        });
    }, [inputBox, msgBox, post, onCreated]);

    return { promptCreateUser, isCreatingUser };
}

export default useUserCreate;
