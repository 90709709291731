import useUsersSearch from '@hooks/useUsersSearch';
import UsersSearchResults from './components/UsersSearchResults';
import styles from './UsersHome.module.css';

function Home() {
    const { status, results } = useUsersSearch();

    if (status === 'idle') return null;
    if (status === 'loading') return <div className={styles.centerMessage}>Searching...</div>;
    if (status === 'failed') return <div className={styles.centerMessage}>Search failed. Please try again.</div>;
    if (status === 'done' && results.length === 0) return <div className={styles.centerMessage}>No users found.</div>;

    return <UsersSearchResults />;
}

export default Home;
