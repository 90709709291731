import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { getCMSApiOrigin } from 'src/config';
import { URLSearchParams } from 'url';

function useCMSFetch() {
    const [accessToken] = useGlobal('accessToken');
    const makeRequest = useCallback(
        async <T>(method: string, endpoint: string, data?: object | undefined): Promise<T | null> => {
            const uri = `${getCMSApiOrigin()}${endpoint}`;
            const headers: Record<string, string> = {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            };

            if (data) {
                headers['Content-Type'] = 'application/json';
            }

            const init: RequestInit = {
                method,
                headers,
                body: data ? JSON.stringify(data) : undefined,
            };

            const resp = await fetch(uri, init);

            if (resp.status >= 400) {
                throw new Error(`Request failed with status code ${resp.status} ${resp.statusText}`);
            }

            if (resp.status === 204) {
                return null;
            }

            return resp.json() as unknown as T;
        },
        [accessToken],
    );

    const get = useCallback(
        async <T>(endpoint: string, query?: URLSearchParams | undefined) => {
            if (query) {
                return makeRequest<T>('GET', `${endpoint}?${query.toString()}`);
            }
            return makeRequest<T>('GET', `${endpoint}`);
        },
        [makeRequest],
    );

    const post = useCallback(
        <T>(endpoint: string, data?: object | undefined) => makeRequest<T>('POST', endpoint, data),
        [makeRequest],
    );

    const put = useCallback(
        <T>(endpoint: string, data?: object | undefined) => makeRequest<T>('PUT', endpoint, data),
        [makeRequest],
    );

    const del = useCallback(<T>(endpoint: string) => makeRequest<T>('DELETE', endpoint), [makeRequest]);

    return { get, post, put, del };
}

export default useCMSFetch;
