import { useCallback, useState } from 'react';
import useFetch from './useFetch';
import useMessageBox from './useMessageBox';

function useUserPasswordReset() {
    const [isBusy, setIsBusy] = useState(false);
    const { msgBox } = useMessageBox();
    const { post } = useFetch();

    const promptPasswordReset = useCallback(
        async (userId: string, screenName: string, onComplete?: (() => void) | undefined) => {
            msgBox({
                title: 'Reset Password',
                icon: 'question',
                buttons: ['yes', 'no'],
                children: (
                    <p>
                        Are you sure you want to reset the password for <strong>{screenName}</strong>? All existing
                        sessions will be kicked out.
                    </p>
                ),
                onClose: async result => {
                    if (result === 'yes') {
                        setIsBusy(true);

                        try {
                            const createResult = (await post(`/users/${userId}/resetPassword`)) as {
                                newPassword: string;
                            };
                            msgBox({
                                title: 'Success',
                                icon: 'info',
                                buttons: ['ok'],
                                children: (
                                    <>
                                        <p>
                                            Password reset! Please copy the password as it will not be displayed again.
                                        </p>
                                        <pre>{createResult.newPassword}</pre>
                                    </>
                                ),
                            });
                            onComplete?.();
                        } catch {
                            msgBox({
                                title: 'Error',
                                icon: 'error',
                                buttons: ['ok'],
                                children: <p>There was a problem resetting the password. Please try again.</p>,
                            });
                        } finally {
                            setIsBusy(false);
                        }
                    }
                },
            });
        },
        [msgBox, post],
    );

    return { promptPasswordReset, isBusy };
}

export default useUserPasswordReset;
