import { ObjectContent } from 'src/models';
import styles from './AssetViewer.module.css';

interface Props {
    asset: ObjectContent;
}

function AssetViewer({ asset }: Props) {
    return (
        <fieldset className={styles.container}>
            <legend>
                {asset.globalId} ({asset.type}): {asset.name || 'Untitled'} | {asset.ownerName}
            </legend>
            {asset.type === 'form' && <pre className={styles.fdo}>{atob(asset.content)}</pre>}
            {asset.type === 'art' && <img src={`data:image/png;base64,${asset.content}`} alt="preview" />}
        </fieldset>
    );
}

export default AssetViewer;
